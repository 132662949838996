/**
 * Publish a new notification
 *
 * @type {string}
 */
export const publishNotification = `
  mutation ($message: String!) {
    publishNotification (message: $message) {
      id
      created
      message
    }
  }
`

/**
 * Update an existing survey
 *
 * @type {string}
 */
export const updateSurveyQuestion = `
  mutation ($id: Int!, $expires: Int!) {
    updateSurveyQuestion (id: $id, expires: $expires) {
      id
      created
      expires
      type
      text
      answers {
        id
        text
      }
    }
  }
`

/**
 * Create a new survey question
 *
 * @type {string}
 */
export const createSurveyQuestion = `
  mutation ($text: String!, $type: SurveyType!, $expires: Int!, $answers: [String!]) {
    createSurveyQuestion (text: $text, type: $type, expires: $expires, answers: $answers) {
      id
      created
      expires
      type
      text
      answers {
        id
        text
      }
    }
  }
`

/**
 * Download covid-19 survey results
 *
 * @type {string}
 */
export const downloadCovidStatus = `
  mutation ($from: Int!, $to: Int!) {
    downloadCovidStatus (from: $from, to: $to) {
      sent
    }
  }
`

/**
 * Mutation for updating desk information
 *
 * @type {string}
 */
 export const updateDesk = `
  mutation ($id: ID!, $capacity: Int, $floor: Int, $area: String, $coordinates: [[[Float]]]) {
    updateDesk(id: $id, capacity: $capacity, floor: $floor, area: $area, coordinates: $coordinates) {
      id,
      capacity,
      floor,
      area,
      geometry {
        type,
        coordinates
      }
    }
  }
`

/**
 * Create a new desk entry
 *
 * @type {string}
 */
export const createDesk = `
  mutation ($capacity: Int!, $floor: Int!, $area: Int!, $coordinates: [[[Float]]]) {
    createDesk(capacity: $capacity, floor: $floor, area: $area, coordinates: $coordinates) {
      id,
      capacity,
      floor,
      area,
      geometry {
        type,
        coordinates
      }
    }
  }
`

/**
 * Update sensor entry
 *
 * @type {string}
 */
export const updateSensor = `
  mutation ($id: ID!, $reportInto: Int) {
    updateSensor (id: $id, reportInto: $reportInto) {
      id
      lastEvent
      type
      reportInto
      firmware
      online
      identity
    }
  }
`

/**
 * Create a new building
 *
 * @type {string}
 */
export const createBuilding = `
  mutation ($name: String!, $address: String!) {
    createBuilding(name: $name, address: $address) {
      id,
      name,
      address
    }
  }
`

/**
 * Update an existing building
 *
 * @type {string}
 */
export const updateBuilding = `
  mutation ($id: Int!, $name: String!, $address: String!, $archive: Boolean) {
    updateBuilding(id: $id, name: $name, address: $address, archive: $archive) {
      id,
      name,
      address,
      archive
    }
  }
`
/**
 * Create a new floor
 *
 * @type {string}
 */
export const createFloor = `
  mutation ($name: String!, $building: Int!) {
    createFloor(name: $name, building: $building) {
      id,
      name,
      level,
      imageUrl,
      building,
      imageWidth,
      imageHeight
    }
  }
`

/**
 * Create a new area
 *
 * @type {string}
 */
export const createArea = `
  mutation ($name: String!, $floor: Int!) {
    createArea(name: $name, floor: $floor) {
      id,
      name,
      floor: floorId
      building: buildingId
    }
  }
`

/**
 * Update an existing area
 *
 * @type {string}
 */
export const updateArea = `
  mutation ($id: Int!, $name: String!, $archive: Boolean) {
    updateArea(id: $id, name: $name, archive: $archive) {
      id,
      name,
      floor: floorId
      building: buildingId
      archive
    }
  }
`

/**
 * Set the webhook url
 *
 * @type {string}
 */
export const setWebhook = `
  mutation ($url: String!, $enabled: Boolean!) {
    setWebhook(url: $url, enabled: $enabled) {
      url
      enabled
    }
  }
`
