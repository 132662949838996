/**
 * Return api access keys
 *
 * @type {string}
 */
export const getApiAccessKeys = `
  query {
    getApiAccessKeys {
      prefix
      expires
      createTime
    }
  }
`

/**
 * Return floor upload URL for active user
 *
 * @type {string}
 */
export const getFloorUploadUrl = `
  query ($id: Int!, $contentType: String) {
    getFloorUploadUrl (id: $id, contentType: $contentType) {
      url
      expires
    }
  }
`

/**
 * Generate signed cookie content
 *
 * @type {string}
 */
export const signProfileContent = `
  query {
    signProfileContent {
      cfPolicy
      cfSignature
      cfKeyPairId
      cfExpires
    }
  }
`

/**
 * Generate signed company cookie content
 *
 * @type {string}
 */
export const signCompanyContent = `
  query {
    signCompanyContent {
      cfPolicy
      cfSignature
      cfKeyPairId
      cfExpires
    }
  }
`

export const getBuildings = `
query {
  buildings: getBuildings {
    id,
    name,
    address
  }

}
`

/**
 * Load the building GeoJSON features (desks)
 *
 * @type {string}
 */
export const loadGeoJSON = `
  query ($building: Int!) {
    getDesks(building: $building) {
      type,
      features {
        type,
        properties {
          id,
          floor,
          capacity,
          area,
          areaLegacy,
          type,
          custom,
          lastEvent,
          hasDock,
          sensorsOnline,
          sensorsTotal
        },
        geometry {
          type,
          coordinates
        }
      }
    }
  }
`

/**
 * Lookup measurements grouped by a specific
 * dimension
 *
 * @type {string}
 */
export const getMeasurementsMeanGrouped = `
  query ($from: Int!, $to: Int!, $building: Int, $floor: Int, $area: Int, $grouped: String!) {
    getMeasurementsMeanGrouped(from: $from, to: $to, building: $building, floor: $floor, area: $area, grouped: $grouped) {
      battery,
      co2,
      humidity,
      lux,
      mic,
      workspace_utilization,
      meeting_utilization,
      temp,
      tvoc,
      count,
      desk,
      capacity
    }
  }
`

/**
 * Load desk data such as the sensors and latest
 * measurements for the specific desk
 *
 * @type {string}
 */
export const loadDeskData = `
  query ($desk: String!, $from: Int!, $to: Int!, $fill: Boolean) {
    getSensorsByDesk(desk: $desk) {
      id,
      lastEvent,
      type,
      reportInto,
      firmware,
      online,
      identity
    }

    getMeasurements: getMeasurementsGeneric(from: $from, to: $to, desk: $desk, fill: $fill) {
      time
      battery
      co2
      humidity
      lux
      mic
      occupancy: workspace_utilization
      temp
      tvoc
      count
    }
  }
`

/**
 * Load data related to the webhook endpoint
 *
 * @type {string}
 */
export const getWebhook = `
  query {
    getWebhook {
      url,
      enabled
    }
  }
`
