import API, {graphqlOperation} from '@aws-amplify/api'
import loader from './loader'
import * as queries from './../gql/queries'
import * as mutations from './../gql/mutations'
import notification from './notification'

export const ACTION = {
  LOAD_KEYS: 'api-load-keys',
  SET_WEBHOOK: 'set-webhook-url',
  GET_WEBHOOK: 'get-webhook-url',
  GENERATE_TOKEN: 'generate-api-token',
  CLEAR_GENERATED: 'clear-generated-api-token'
}

export const LOAD = ACTION

const createApiAccessKeyMutation = `
  mutation {
    createApiAccessKey {
      prefix
      generatedToken
    }
  }
`

export const getApiAccessKeys = () => {
  return loader.load(LOAD.LOAD_KEYS, (dispatch) => {
    return API.graphql(graphqlOperation(queries.getApiAccessKeys))
      .then((result) => dispatch({ type: ACTION.LOAD_KEYS, data: result.data.getApiAccessKeys }))
  })
}

export const clearGenerated = () => ({ type: ACTION.CLEAR_GENERATED })

export const createApiAccessKey = () => {
  return loader.load(LOAD.GENERATE_TOKEN, (dispatch) => {
    return API.graphql(graphqlOperation(createApiAccessKeyMutation))
      .then((result) => dispatch({ type: ACTION.GENERATE_TOKEN, data: result.data.createApiAccessKey }))
      // Refresh access keys
      .then(() => dispatch(getApiAccessKeys()))
  })
}

export const setWebhook = (url, enabled) => {
  return loader.load(LOAD.SET_WEBHOOK, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.setWebhook, { url, enabled }))
      .then((result) => dispatch({ type: ACTION.SET_WEBHOOK, data: result.data.setWebhook }))
      .then(() => dispatch(notification.success('Webhook successfully updated')))
  })
}

export const getWebhook = () => {
  return loader.load(LOAD.GET_WEBHOOK, dispatch => {
    return API.graphql(graphqlOperation(queries.getWebhook))
      .then(result => dispatch({ type: ACTION.GET_WEBHOOK, data: result.data.getWebhook }))
  })
}

const initial = {
  data: [],
  generated: null
}

export const reducer = (state = initial, action) => {
  switch (action.type) {
    case ACTION.CLEAR_GENERATED:
      return { ...state, generated: null }
    case ACTION.LOAD_KEYS:
      return { ...state, data: action.data }
    case ACTION.GET_WEBHOOK:
      return {...state, webhook: action.data }
    case ACTION.SET_WEBHOOK:
      return {...state, webhook: action.data }
    case ACTION.GENERATE_TOKEN:
      return {...state, generated: action.data }
    default:
      return state;
  }
}
